<template>
  <div class="bookstore">
     <div class="volunteer-continar">
        <div class="redtitle">
        <span>网上书屋</span>
        <!-- <span @click="jumpTotextdetail">>>&nbsp;&nbsp;更多</span> -->
    </div>
    <div class="projectintroduct">
        <ul class="workinfo-list">
            <li 
            v-for="(item,index) in bookstores" 
            :key="item.id"
            @click="$goto('/detailShow',{id:item.id})"
            >
                <p>{{item.title}}</p>
                <span>{{item.publishDate | formateData}}</span>
            </li>
        </ul>
        <div class="pagination">
        <el-pagination
        class="bs-pagination"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
        </el-pagination>
        </div>
    </div>
    </div>
  </div>
</template>

<script>
import apiUrls from '@api'
export default {
  name: 'Bookstore',
  data () {
      return {
      page: 1,
      size: 10,
      total: 0,
      bookstores:[]
      }
    },
    created () {
    this.bookstorelist()
    this.handleCurrentChange()
    },
    methods : {
    handleSizeChange(val) {

    },
    handleCurrentChange(val) {
      apiUrls.getvolunteer({node: 'xxpt_wssw', page: val===1?0:val}).then(res=>{
      this.total = res.results.pager.totalElements;
      this.bookstores= res.results.data
      })
    },
    bookstorelist() {
      apiUrls.getlearnplat({node: 'xxpt_wssw'}).then(res=>{
      this.bookstores= res.results.data
      })
    },
    jumpTotextdetail() {
      this.$router.push({
        path: '/textDetail',
        query: { name: "网上书屋", type: 'xxpt_wssw'}
      })
    }
    }
}
</script>

<style lang="less" scoped>
.bookstore {
    background-color: #f5f5f5;
     .volunteer-continar{
        width: 1200px;
        min-height: 400px;
        padding-bottom: 40px;
        margin: 0 auto;
        background-color: #f5f5f5;
        &::before {
            display: block;
            content: ".";
            clear: both;
            height: 0;
            overflow: hidden;
            visibility: hidden;
        }
    }
    .redtitle  {
    cursor: pointer;
    margin-top: 10px;
    font-size: 23px;
    color: #333333;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    span {
        &:nth-child(1) {
        display: flex;
        &::before {
        content: "";
        display: block;
        height: 20px;
        width: 20px;
        background: url('../../../assets/img/title.png') no-repeat center center;
       }
     }
       &:nth-child(2) {
           color: #ABABAB;
           font-size: 17px;
       }
    }
   
}
.projectintroduct {
    min-height: 577px;
    background-color: #ffffff;
    overflow: hidden;
    padding: 40px 23px 40px 40px;
    box-shadow: 0px 0px 18px 0px rgba(14,14,14, 0.13);
    .workinfo-list {
        cursor: pointer;
        li {
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;
			padding: 10px 0;
            &:last-child{
                margin-bottom: 0;
            }
            p{
                color: #333333;
				&:hover {
					color: #d1292e;
				}
                display: flex;
                &::before {
                    content: "";
                    display: block;
                    height: 16px;
                    width: 26px;
                    background: url('../../../assets/img/list-little.png') no-repeat center center;
                }
            }
            span {
                color: #ABABAB;
            }
        }
    }
    .pagination {      
            min-width: 403px;
            margin: 0 auto;
            margin-top: 40px;
            // .bs-pagination {
            //  width: 656px;
            // }
        }
}
}
</style>
